import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";
import { HashLink } from "components/Common/HashLink";
import TellMeWhyResultSidePanel from "components/Common/Modals/tellMeWhy/TellMeWhyResultSidePanel";
import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";
import SpreadTheWordPanel from "components/Entities/Page/SpreadTheWordPanelAsync";
import EntityFollowButton from "components/Follow/EntityFollowButtonAsync";
import PodcastButtons from "components/Podcast/PodcastButtonsAsync";

import EntityRatingButton from "../../Common/EntityRatingButton";
import ExportButton from "../Insights/components/ExportButton";
import TellMeWhyButton from "../Insights/TellMeWhyButton";

import ratingActions from "actions/rating";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import { podcastVerbLabels } from "utils/rating";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  divider: {
    marginTop: "1rem",
    marginBottom: "1rem",
    borderTop: "1px solid #F3F3F3",
  },
  description: {
    ...gStyles.fontRegularItalic,
    lineHeight: "1rem",
    fontSize: "0.75rem",
    color: "#959FB6",
    margin: "0 1rem",
    textAlign: "center",
  },
  buttonSeparator: {
    marginTop: ".5rem",
  },
  emailSubscribeContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "1rem 0",
    width: "100%",
  },
  container: {
    display: "flex",
  },
  maxHeight: {
    maxHeight: "2.75rem",
  },
  podcastButtonsContainer: {
    maxHeight: "4.563rem",
  },
  contactButtonContainer: {
    position: "relative",

    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  internalContactButtonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  lockIcon: {
    marginLeft: "1rem",
    lineHeight: "1em",
    pointerEvents: "none",
  },
  buttonWithBadge: {
    position: "relative",
  },
  buttonBadge: {
    ...gStyles.fontSemiBold,
    position: "absolute",
    background: colours.primaryTextBackground,
    color: colours.white,
    fontSize: 9,
    margin: "0 auto",
    borderRadius: "2rem",
    textTransform: "uppercase",
    minWidth: 6,
    minHeight: 6,
    zIndex: 2,
    marginLeft: -6,
    left: "1rem",
    padding: "0.3rem 0.4rem",
    top: "-0.5rem",
  },
};

const buttonStyles = {
  button: {
    ...gStyles.avalonBold,
  },
};

const customStyles = {
  button: {
    height: "40px",
    marginRight: ".5rem",
  },
};

const followButton = {
  iconButton: {
    fontSize: "1rem",
  },
};

const ratingsButton = {
  button: {
    height: "40px",
    marginRight: ".5rem",
  },
};

const sidebarStyles = {
  pageSidebarPanel: {
    [ScreenSizes.lgAndAbove]: {
      marginTop: "-2rem",
    },
  },
};

const SpreadTheWord = (props) => {
  const { mobile, podcast } = props;
  const { styles } = useStyles(baseStyles, props);
  const userHasPro = useUserHasPro();
  const userRating = podcast?.getIn(["user_data", "rating", "rating"]);
  const podcastId = podcast?.get("id");
  const user = useLoggedInUser();

  const tellMyWhyPrompt =
    podcast?.getIn(["user_data", "pro_data", "campaign_prompt_responses"]) ||
    Map({});

  const showTellMeWhyPrompt = user
    ?.get("campaign_prompt_podcast_ids")
    ?.includes(podcastId);

  const { openPodcastRatingModal } = useActionCreators(ratingActions);
  const userIsInternal = userHasInternalPermission();

  const handleRatingsChange = useCallback(
    (newRating) =>
      openPodcastRatingModal(podcast?.get("id"), {
        entity: podcast,
        initialRating: newRating,
      }),
    [openPodcastRatingModal, podcast]
  );

  const handleChangeRatingClick = useCallback(
    () => openPodcastRatingModal(podcast?.get("id"), { entity: podcast }),
    [openPodcastRatingModal, podcast]
  );

  const handleContactPodcastClick = useCallback(() => {
    sendGAEvent({
      action: "clickContactPodcastButton",
      context: "SpreadTheWord",
      podcast_id: podcast?.get("id"),
      podcast_title: podcast?.get("title"),
      userHasPro,
    });
  }, [podcast, userHasPro]);

  const headerInternal = (
    <Fragment>
      <div className={css(styles.container)}>
        <EntityFollowButton
          entityName="Podcast"
          entity_type="podcast"
          entity={podcast}
          variation="pink"
          fontSize="1rem"
          flat
          key="spreadTheWordFollowButton"
          customStyles={customStyles}
          styles={followButton}
        />

        <EntityRatingButton
          entityType="podcast"
          rating={userRating}
          onClick={handleChangeRatingClick}
          onRatingsChange={handleRatingsChange}
          verbLabels={podcastVerbLabels}
          passedButtonStyles={ratingsButton}
        />
      </div>

      <PodcastButtons
        entity_type="podcast"
        entity={podcast}
        key="spreadTheWordPodcastButtons"
      />
    </Fragment>
  );

  const header = (
    <Fragment>
      <div>
        <EntityFollowButton
          entityName="Podcast"
          entity_type="podcast"
          entity={podcast}
          variation="pink"
          fontSize="1rem"
          flat
          key="spreadTheWordFollowButton"
          customStyles={userIsInternal && customStyles}
          styles={followButton}
        />

        <div
          className={css(styles.buttonSeparator)}
          key="spreadTheWordFollowButtonSeparator"
        />

        <EntityRatingButton
          entityType="podcast"
          rating={userRating}
          onClick={handleChangeRatingClick}
          onRatingsChange={handleRatingsChange}
          verbLabels={podcastVerbLabels}
        />
      </div>

      <PodcastButtons
        entity_type="podcast"
        entity={podcast}
        key="spreadTheWordPodcastButtons"
      />
    </Fragment>
  );

  const contactPodcastButton = (
    <StandardButton
      variation={"white"}
      fontSize=".75rem"
      flat
      customStyles={buttonStyles}
      key="spreadTheWordContactButton"
      onClick={handleContactPodcastClick}
      outerComponent={HashLink}
      to={getPodcastUrl(podcast, "/insights#podcast-contacts")}
    >
      Contact This Podcast
      {!userHasPro && (
        <span className={css(styles.lockIcon)}>
          <FontAwesomeIcon icon={faLock} />
        </span>
      )}
    </StandardButton>
  );

  return (
    <>
      <SpreadTheWordPanel
        entity={podcast}
        entity_type="podcast"
        sidebarStyles={{
          pageSidebarPanel: {
            borderTopLeftRadius: "32px",
            borderTopRightRadius: "32px",
          },
        }}
        headerContent={userIsInternal ? headerInternal : header}
        mobile={mobile}
        renderOnMount
      />

      {showTellMeWhyPrompt && (
        <PageSidebarPanel styles={sidebarStyles} renderOnMount>
          <div className={css(styles.internalContactButtonContainer)}>
            <TellMeWhyResultSidePanel
              podcastId={podcastId}
              tellMyWhyPrompt={tellMyWhyPrompt}
            />
          </div>
        </PageSidebarPanel>
      )}
      {!userIsInternal && (
        <PageSidebarPanel styles={sidebarStyles} renderOnMount>
          <div className={css(styles.contactButtonContainer)}>
            {!mobile && <TellMeWhyButton podcastId={podcastId} />}
            {contactPodcastButton}
            <ExportButton podcastId={podcast?.get("id")} />
          </div>
        </PageSidebarPanel>
      )}
      {userIsInternal && (
        <PageSidebarPanel styles={sidebarStyles} renderOnMount>
          <div className={css(styles.internalContactButtonContainer)}>
            {!mobile && <TellMeWhyButton podcastId={podcastId} />}
            {contactPodcastButton}
            <ExportButton podcastId={podcast?.get("id")} />
          </div>
        </PageSidebarPanel>
      )}
    </>
  );
};

SpreadTheWord.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
};

SpreadTheWord.defaultProps = {
  mobile: false,
};

export default SpreadTheWord;
